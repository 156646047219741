import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/layout";
import SEO from "../components/seo";
import icon from "../images/icon-news.svg";

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




const NewsItems = ({ data }) => {
  const newsItems = data.allContentfulNewsItem.edges;
  return (
    <Layout>
      <SEO title="Lawtech Hub News" />
      <div className="">
        <Img
          className="object-cover w-full h-64"
          fluid={data.file.childImageSharp.fixed}
          alt="Header image"
        />

      </div>
      <div className="container">
        <h1 className="title">Playbook</h1>
      </div>
      <div className="bg-white w-full border-t-2 border-b border-white">
        <div className="container flex py-6 my-2 self-center">
          <div className="w-auto lg:w-1/2 self-center">
            <h3 className="pt-6 text-center md:text-left">
              {documentToReactComponents(data.contentfulPageIntro.intro.json)}
            </h3>
          </div>
          <div className="w-1/2 hidden lg:block">
            <img src={icon} className="h-64 mb-0 float-right mx-12" alt="LawTech Hub Icons" />
          </div>
        </div>
      </div>


      <div className="container mt-12">


        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 -mx-2 mt-16">
          {newsItems.map(({ node: post }) => (




            <Link to={`/playbook/${post.slug}`} key={post.id} className="hover-card bg-white shadow-sm rounded-sm mb-10 group cursor-pointer">
              <Img
                className="object-cover h-48 rounded-t-sm"
                fluid={post.heroImage.fixed}
                alt="Header"
              />
              <div className="px-4 pt-4 pb-6">
                <div className="tags mb-4">
                  {post.tags.map(tag => (
                    <span className="text-xs text-purple-600 border border-purple-200 px-1" key={tag}>
                      {tag}
                    </span>
                  ))}
                </div>
                <h3 className="group-hover:text-purple-800">{post.title}</h3>
                <p className="text-sm pb-2">
                  {post.description.description}
                </p>
                <div className="absolute bottom-0 mb-4 text-xs font-serif italic text-gray-600">{post.publishDate}</div>
              </div>
            </Link>



          ))}
          <span className="mb-24" />

        </div>
      </div>
    </Layout>
  );
};
export default NewsItems;
export const query = graphql`
  query headerImageQueryAndNewsItemsPageQuery {
    contentfulPageIntro(page: { eq: "News" }) {
      intro {
        json
      } 
    }
    allContentfulNewsItem(sort: {order: DESC, fields: publishDate}, limit: 21) {
      edges {
        node {
          id
          title
          tags
          publishDate(formatString: "D MMMM, YYYY")
          slug
          body {
            body
          }
          description {
            description
          }
          heroImage {
            fixed(width: 400) {
              base64
              width
              height
              src
              srcSet
            }
          }
          tags
        }
      }
    }
    file(relativePath: { eq: "banner-news.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }    
  }



`;